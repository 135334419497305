.about {
  padding: 0 10%;
  display: flex;
  align-items: flex-start;
  margin-bottom: 10%;
}

.about-title {
  margin: 0;
}

.about-me {
  width: 100%;
  position: sticky;
  top: 15%;
}

.about-para {
  width: 90% !important;
  margin-bottom: 2rem;
}

.about-skills {
  padding: 0 0 0 7%;
  border-left: 0.2px solid rgba(136, 158, 168, 0.2);
  margin-left: 0%;
}

.skills {
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  gap: 2.5rem;
}

.skill {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 1rem;
  align-items: center;
  color: white;
}

.skill img {
  width: 50px;
}

.skill-title {
  color: white;
  font-size: 2rem;
  margin: 0 0 1rem 0;
}

.soft-skill {
  color: white;
  font-size: 1.2rem;
  text-transform: lowercase;
  border-radius: 50px;
  margin: 0;
  font-family: 'Orbit', sans-serif;
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .about-skills {
    padding: 0 0 0 5%;
    border-left: 0.2px solid rgba(136, 158, 168, 0.2);
    margin-left: 0%;
    width: 100% !important;
  }

  .skills {
    margin-top: 2rem;
    display: flex;
    flex-wrap: wrap;
    gap: 2.5rem;
  }

  .skill {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 1rem;
    align-items: center;
    color: white;
  }

  .skill img {
    width: 40px;
  }

  .skill-title {
    color: white;
    font-size: 1.3rem;
    margin-bottom: 1rem;
  }

  .soft-skill {
    color: white;
    font-size: 1rem;
    text-transform: lowercase;
    border-radius: 50px;
    margin: 0;
    font-family: 'Orbit', sans-serif;
  }
}

/* for mobile and tablet devices */

@media only screen and (max-width: 900px) {
  .about {
    padding: 5%;
    flex-direction: column;
    margin-bottom: 25%;
  }

  .about-me {
    position: static;
  }

  .about-para {
    width: 100% !important;
  }

  .about-skills {
    padding: 0;
    border-left: 0;
    margin-top: 20%;
  }

  .skills {
    margin-top: 2rem;
    display: flex;
    flex-wrap: wrap;
    gap: 2.5rem;
  }

  .skill {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 1rem;
    align-items: center;
    color: white;
  }

  .skill img {
    width: 35px;
  }

  .skill p {
    font-size: 0.75rem;
  }

  .skill-title {
    color: white;
    font-size: 1.3rem;
    margin-bottom: 1rem;
  }

  .soft-skill {
    color: white;
    font-size: 1rem;
    text-transform: lowercase;
    border-radius: 50px;
    margin: 0;
    font-family: 'Orbit', sans-serif;
  }
}
